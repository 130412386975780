import React, { Component } from 'react';
import axios from 'axios';
import './painel.css';
import {STORAGE_Email}  from '../../../../routes/auth';
import {Accordion , AccordionSummary,AccordionDetails,Typography,CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';

class Painel extends Component {
  constructor(props){
    super(props);
    this.state = { notificacoes:{}}
  }
  
  setNotificacoes = (notificacoes) =>{
    this.setState({notificacoes})
  }

  render() {

    let notificacoes = this.state.notificacoes;
    let noti_html = [];
    Object.keys(notificacoes).reverse().forEach(function(key) {
      noti_html.push(
                    <Accordion defaultExpanded={true} style={{margin:'3%',border:'2px',borderColor:'#D5D5D5',backgroundColor:'#FBFBFB'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{backgroundColor:'#288E8D',borderRadius:'50px',color:'#fff',fontSize:'35px'}}/>}
                                               aria-controls="panel1a-content"
                                               id="panel1a-header">
                         <Typography ><b>{notificacoes[key].Titulo + " ("+notificacoes[key].Data+")" }</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography >{notificacoes[key].Texto}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    );
    });
    return (
    <div id={"painel"}  className="h-100" style={{"backgroundColor":"#fff","border-radius":"5px","box-shadow": "1px 1px 6px 6px rgba(0, 0, 0, .16)",'overflow': 'scroll','overflow-x':'hidden'}}>
      <h3 className="p-3 pt-4 pl-5"><b>Notificações</b> </h3>
      <hr />
      {noti_html}
      <div id={'notificacoes'} className="text-center">
      <CircularProgress id="loader" />
      </div>
    </div>
    );
  }
  componentDidUpdate() {
    this.renderNoticias();
  } 

  componentDidMount() {
    this.renderNoticias();
  }

  renderNoticias = () =>{
    let id = localStorage.getItem(STORAGE_Email);
    let grafico_container = document.getElementById('notificacoes');
    axios.get(process.env.REACT_APP_API_URL + 'notificacoes/'+id)
    .then(res => {
      grafico_container.innerHTML = '';
      this.setNotificacoes(res.data)
      Object.keys(res.data).forEach(function(key){
        if(!res.data[key].Lida){
          axios.put(process.env.REACT_APP_API_URL + 'notificacoes/lida',{notificacao:key, email:id},{headers:{'Content-Type': 'aplicattion/json','X-Requested-With': 'XMLHttpRequest'}});
        }
      });
    });
 }

}

export default Painel;
