import React, { Component } from 'react';
import { Container} from 'react-bootstrap';
import './graficosicons.css';
import Icons from './icons/icons';


class Graficosicons extends Component {
  
  render() {
    let listIcons = []
    for(let key in this.props.graficos){
      listIcons.push((
        <Icons key={key}
               alteraGraficoatual={this.props.alteraGraficoatual} 
               alteraNomeGrafico={this.props.alteraNomeGrafico} 
               alteraSubgraficoatual={this.props.alteraSubgraficoatual}
               nome={this.props.graficos[key].nome} 
               grafico={key}
               imagem={this.props.graficos[key].imagem} />
        ))
      }

    return (
      
      <Container id={"navbuttons-graficos"} fluid={true} className="h-100 p-0">
      <ul >
       {listIcons}
      </ul>
    </Container>
    );
  }
}

export default Graficosicons;
