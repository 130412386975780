import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Painel from '../painel/painel';
import './content.css';

import {connect} from 'react-redux';
import {trocaSidebar} from '../../../../actions/actionTrocaSidebar';
import {bindActionCreators} from 'redux';

class Content extends Component {
  render() {
    this.props.trocaSidebar('Energy Report');
    return (
    <Container fluid={true} className="h-100">
      <Row className="h-100">
        <Col md={12} className="h-100 p-1"><Painel /></Col>
      </Row>
    </Container>
    );
  }
}

function mapStateToProps(state){
  return {
    sidebar_ativa:state.sidebar_ativa
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({trocaSidebar:trocaSidebar}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Content);
