import React, { Component } from 'react';
import axios from 'axios';
import Posts from './posts'
import './painel.css';
import {CircularProgress, Button} from '@material-ui/core';

class Painel extends Component {
  
  constructor(props){
    super(props);
    this.state = { posts:{}}
  }

  setPosts = (posts) =>{
    this.setState({posts})
  }

  render() {
    let posts_render = [];
    let ver_mais;
    for(let key in this.state.posts){
      if(key >= 4) break;
      let post = this.state.posts[key];
      posts_render.push((<Posts content={post.excerpt.rendered} 
                                id={post.id}
                                link={post.link}
                                data_post={post.date}
                                title={post.title.rendered}/>));
    };

    if (posts_render.length > 1){
      ver_mais = (<Button target="_blank" href="https://www.energiasmart.com.br/noticias/" style={{backgroundColor:'#385682',color:'#fff'}}>
      Ver anteriores
      </Button>);
    }  
    return (
      <div id={"painel"} className="h-100" style={{"backgroundColor":"#fff","border-radius":"5px","box-shadow": "1px 1px 6px 6px rgba(0, 0, 0, .16)",'overflow': 'scroll','overflow-x':'hidden'}}>
      
      <h3 className="p-3 pt-4 pl-5"><b>Notícias</b> </h3>
      <hr />
      {posts_render}
      <div id={'noticias'} className="text-center">
      <CircularProgress style={{display:'none'}}/>
      </div>
      <div id={'noticias-anteriores'} className="text-center">
        {ver_mais}
      </div>
      </div>
    );
  }

  componentDidMount() {
    this.renderNoticias();
  }

  renderNoticias = () =>{
    let teste = document.getElementById('noticias');
    teste.innerHTML = '<div class="text-center MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style="width: 70px; height: 70px;margin-top:auto;"><svg class="MuiCircularProgress-svg" viewBox="22 22 44 44"><circle class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate" cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"></circle></svg></div>';
    axios.get('https://www.energiasmart.com.br/wp-json/wp/v2/posts')
    .then(res => {
      teste.innerHTML = "";
      this.setPosts(res.data)
    });
 }

}

export default Painel;
