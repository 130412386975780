import React, { Component } from 'react';
import {Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import './content.css';
import Navbardash from './navbardash';
import Sidebar from './sidebar';
import Infogeral from '../pages/infogeral';
import Graficos from '../pages/graficos/content';
import Relatorios from '../pages/relatorios/content';
import Noticias from '../pages/noticias/content';
import Notificacoes from '../pages/notificacoes/content';
import InfoSetorial from '../pages/info-setorial/content';
import Admin from '../pages/admin/content';
import Sobre from '../pages/sobre/content';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {trocaGrafico} from '../../actions/actionTrocaGrafico';

const routes = [
  {
    path: "/dashboard/admin",
    exact: true,
    main: () => <Admin />
  },
  {
    path: "/dashboard",
    exact: true,
    main: () => <Infogeral />
  },
  {
    path: "/dashboard/graficos",
    main: () => <Graficos />
  },
  {
    path: "/dashboard/relatorio",
    main: () => <Relatorios />
  },
  {
    path: "/dashboard/noticias",
    main: () => <Noticias />
  },
  {
    path: "/dashboard/notificacoes",
    main: () => <Notificacoes />
  },
  {
    path: "/dashboard/info-setorial",
    main: () => <InfoSetorial />
  },
  {
    path: "/dashboard/sobre",
    main: () => <Sobre />
  }
];

class Content extends Component {

  constructor() {
    super();
    this.state = {
      pagina : 0,
      id:0
    };
  }

  render() {
    if(this.props.grafico){
    this.props.trocaGrafico(this.props.grafico);
    }
    return (
      <Container fluid={true}>
      <Row style={{'height':'10%'}}>
        <Col md={12} className="h-100" style={{"padding":"0"}} > <Navbardash /> </Col>
      </Row>
      <Row style={{'height':'90%'}}>
        <Col md={2}  className=" side-parent" style={{"backgroundColor":"#28466f","padding":"0",'height':'100%'}}> <Sidebar /> </Col>
        <Col md={10} sm={12} style={{"backgroundColor":"#f5f5f5",'height':'100%'}}>{routes.map((route, index) => (<Route key={index} path={route.path} exact={route.exact} render={route.main} />))} </Col>
      </Row>
    </Container>
    );
  }

}

function mapStateToProps(state){
  return {
    grafico_ativo:state.grafico_ativo
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({trocaGrafico:trocaGrafico}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Content);
