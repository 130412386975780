import React, {Component, useEffect, useState} from 'react';
import './painel.css';
import {Container} from 'react-bootstrap';
import {MobilePDFReader} from 'react-read-pdf';
import axios from 'axios';
import {CircularProgress} from '@material-ui/core';

const Painel = (props) => {

    const [numPages, setNumPages] = useState(1);
    const [pdfUrl, setPdfUrl] = useState('');
    const [pageNumber, setPageNumberState] = useState(1);

    const getPdfUrl = async () => {
        await axios.get(process.env.REACT_APP_API_URL + '/pdf')
            .then(res => {
                console.warn(process.env.REACT_APP_API_URL + '/getpdf/public/' + res.data.data.Arquivo);
                setPdfUrl(process.env.REACT_APP_API_URL + '/getpdf/public/' + res.data.data.Arquivo);
            }).catch(err => {
                console.warn('err')
                console.warn(err)
            });
    }

    useEffect(() => {
        getPdfUrl();
    }, []);


    const setPageNumber = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= numPages) {
            document.getElementById('pdfview').scrollTop = 0;
            setPageNumberState(pageNumber);
        }
    }

    const onDocumentLoadSuccess = (numPages) => {
        setNumPages(numPages);
    }

    return (
        <Container className="painel" fluid={true}>
            <div id={"pdfview"} style={{height: '100%', overflowX: 'unset'}}>
                {pdfUrl ?
                    <MobilePDFReader scale={1} isShowFooter={false} isShowHeader={false}
                                     onDocumentComplete={onDocumentLoadSuccess} url={pdfUrl}/>
                    :
                    <div className={"loader"}>
                        <CircularProgress style={{height: 80, width: 80}}/>
                    </div>
                }
            </div>
        </Container>
    );

}

export default Painel;
