import React, { Component } from 'react';
import { Form, Button} from 'react-bootstrap';
import { Link ,Redirect} from "react-router-dom";
import SVG from 'react-inlinesvg';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';

import {ReactComponent as IcBack } from '../../assets/ic_back.svg';
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})

const INITIAL_STATE = {
  email: '',
  error:null,
  redirect:false
};

class Forgotpassword extends Component {
  constructor(props){
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  setError = (error) => {
    this.setState({error});
  }

  setRedirect = (redirect) =>{
    this.setState({redirect})
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.setError(null);
  };

  render() {
    const {
      email,
      error,
      redirect
    } = this.state;

    if(redirect){
      return <Redirect path to="/" />
    } else {
      return (
        <Form className="h-100">
          <div style={{'padding-right': '35%'}}className="d-flex justify-content-center">
            <Link to="/">
              <IcBack />
            </Link>
          </div>
          <h3 style={{fontSize:'36px',color:'#385682'}} className="loginInput d-flex justify-content-center" ><b>Recuperar senha</b></h3> 
          <p style={{paddingLeft:'32%',paddingRight:'32%'}} className="d-flex justify-content-center">Insira seu e-mail para receber o código e recuperar sua senha.</p>     
          <Form.Group controlId="formBasicEmail" className="loginInput d-flex justify-content-center " >
            <TextField  style={{width:'35%'}}variant="outlined" onChange={this.onChange} name="email" type="email" label="Digite seu e-mail" />
          </Form.Group>
          <Form.Group className="d-flex justify-content-center">
            <Button  onClick={this.recuperarSenha} className="loginSubmit" >
              Recuperar
            </Button>
          </Form.Group>
          <Form.Group className="d-flex justify-content-center" style={{color:'red'}}>
          {error && <p>{error}</p>}
          </Form.Group>
      </Form>
      );
    }
  }

  recuperarSenha = (e) =>{
    axios.post(process.env.REACT_APP_API_URL + 'auth/forgot',{
      email: this.state.email
    },{headers:{'Content-Type': 'application/json','X-Requested-With': 'XMLHttpRequest'}})
    .then(res => {
      this.setRedirect(true);
      Toast.fire({
        type: 'success',
        title: 'E-mail de recuperação enviado com sucesso'
      })
    })
    .catch(error => {
      this.setError("Não identificamos cadastro deste e-mail em nosso sistema");
    });
  }
}

export default Forgotpassword;
