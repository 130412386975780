import './App.css';
import {Component} from "react";
import Home from './home/content'

class App extends Component {
  render() {
    return (
        <Home />
    );
  }
}

export default App;
