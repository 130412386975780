
import React, { Component } from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from './auth';

class PrivateRoute extends Component {
    constructor(props){
        super(props);    
    }

    render(){
        if(isAuthenticated()){
            return(
                <Route {... this.props}/> 
            );
        }else {
            return(
                <Redirect to='/' /> 
            );
        }
    }
}

export default PrivateRoute;
