import React, { Component } from 'react';
import axios from 'axios';
import './grafico.css';
import SVG from 'react-inlinesvg';
import { STORAGE_Nome,STORAGE_Units } from '../../../../routes/auth';
import {connect} from 'react-redux';
import {Button,CircularProgress} from '@material-ui/core';

import {ReactComponent as  Seta } from '../../../../assets/right-arrow.svg';
const postscribe = require('postscribe');
export class Grafico extends Component {
  
  render() {
    let buttons = [];
    if(this.props.grafico_atual.sub_graficos){
      for(let key in this.props.grafico_atual.sub_graficos){
        buttons.push(<Button variant="outlined"
                             key={key} 
                             className="subgraphIcon" 
                             onClick={() => this.triggerUpdate(key)}
                             > {this.props.grafico_atual.sub_graficos[key]}  <Seta /> </Button>)
      }
    }
    if(this.props.unidade.nome === 'TOTAL'){
      
    }
    return (
      
      <div  className="h-100 graphPanel" >
        <div className="text-center">
          <h3>{this.props.nome_grafico}</h3>
        </div>
        <div className="text-center" id="grafico"> 
        </div>
        <div className="text-center" style={{backgroundColor:'#F3F3F3','margin-top':'7px'}}>
          {buttons}
        </div>
        <CircularProgress style={{display:'none'}}/>
      </div>
      );
  }
  componentDidMount() {
    this.renderSubGrafico(this.props.grafico_ativo,localStorage.getItem(STORAGE_Nome),this.props.unidade.nome)
  }

  componentDidUpdate() {
    if(this.props.sub_grafico_atual){
      this.renderSubGrafico(this.props.sub_grafico_atual,localStorage.getItem(STORAGE_Nome),this.props.unidade.nome)
    }else {
      this.renderSubGrafico(this.props.grafico_atual.id,localStorage.getItem(STORAGE_Nome),this.props.unidade.nome)
    }
  }

  renderSubGrafico = ( grafico ,nome , unidade) =>{
    let grafico_container = document.getElementById('grafico');
    grafico_container.innerHTML = '<div class="text-center MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style="width: 40px; height: 40px;"><svg class="MuiCircularProgress-svg" viewBox="22 22 44 44"><circle class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate" cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"></circle></svg></div>';
    let unidades = localStorage.getItem(STORAGE_Units).split(',');
    if(unidades.length > 2){
    //Regra para renderizar gráficos de Demanda
    if(unidade === 'TOTAL' && (grafico === 7 || grafico === 8)  ) {
      grafico_container.innerHTML = "<h3>Para visualizar os dados deste gráfico, você deve selecionar uma unidade.</h3>";   
      return false;   
    }
    if(grafico === 13){
      grafico_container.innerHTML = "<h3>Não existem dados para esse indicador de sua empresa.</h3>";   
      return false; 
    }
    }
    axios.get(process.env.REACT_APP_API_URL + 'core/'+nome + '/'+unidade+'/grafico/'+grafico,{	headers: {
      'X-Content-Type-Options': 'nosniff',
      'Accept': 'text/html',
      'Content-Type': 'text/html'
    },})
    .then(res => {
      grafico_container.innerHTML = "";
      postscribe('#grafico',res.data);
      setTimeout(() => {
        if(unidade === 'TOTAL') {
          if(grafico === 10) {
            if(unidades.length > 2){
            document.querySelector("#grafico").querySelector('#fatura-tusd').style.display = "none";
            document.querySelector("#auditoria").classList.remove('col-md-8');
            document.querySelector("#auditoria").classList.add('col-md-12');
            }
          }
        }else {
          if(grafico === 3) {
            document.querySelector("#contrato").style.display = 'none';
          }
        }
      }, 100);
    }).catch(error =>{
      grafico_container.innerHTML = "<h3>"+error.response.data.message+"</h3>";
    });
 }

  triggerUpdate = (grafico) => {
    this.props.alteraNomeGrafico(grafico,grafico); 
    this.props.alteraSubgraficoatual(grafico);
  }
}

function mapStateToProps(state){
  return {
    unidade:state.unidade_ativa,
    grafico_ativo:state.grafico_ativo
  };
}

export default connect(mapStateToProps)(Grafico);
