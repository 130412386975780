import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Markup } from 'interweave';

import './posts.css';

const monthNames = ['',"JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
  "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
];

class Posts extends Component {
  
  render() {
    let date = this.props.data_post.split('T');
    date = date[0].split('-');
    let ano = date[0];
    let mes = date[1];
    let dia = date[2];
    let content;
    content = this.props.content.replace(/<a/g,'<a target="_blank" rel="noopener noreferrer"')
    
    return (
        <Container fluid={true} className="pb-5" >
          <Row>
            <Col md={1} >
              <h3 className="day text-right">{dia}</h3>
              <hr/>
              <div className="text-right">
              <span className="month">{monthNames[Number(mes)]}   </span>
              <span className="year">{ano}</span>
              </div>
            </Col> 
            <Col md={11} >
            <h3 className="title"> {this.props.title}</h3>
            <Markup className="content" content={content} /> 
            </Col>
          </Row>  
        </Container>
    );
  }

}

export default Posts;
