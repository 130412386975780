import React, { Component } from 'react';
import '../graficosicons.css';
import './icons.css';
import SVG from 'react-inlinesvg';
import {Button} from '@material-ui/core';
import SvgIcons from "./allIcons";

class Graficosicons extends Component {

  render() {

      const MyIcon = SvgIcons[this.props.imagem];

    return (
        <Button className="graphIcon" onClick={() => this.renderGrafico(this.props.grafico)}>
        <li className="w-100">
          <div className="svg">
            <MyIcon />
          </div>
          <hr className="w-100" />
          <footer>
            {this.props.nome}
          </footer>
        </li>
        </Button>
    );
  }

 renderGrafico = (grafico) =>{
    this.props.alteraNomeGrafico(grafico); 
    this.props.alteraGraficoatual(grafico);
    this.props.alteraSubgraficoatual(0);
}

}

export default Graficosicons;
