import React, { Component } from 'react';
import './painel.css';
import { Container} from 'react-bootstrap';
import axios from 'axios';
const postscribe = require('postscribe');

class Painel extends Component {
  constructor(props){
    super(props)
    this.state = { content: []}
  }

  setContent = (content) => {
    this.setState({content});
  }

  renderNoticias = () =>{
    axios.get('https://www.energiasmart.com.br/wp-json/wp/v2/pages/5724')
    .then(res => {
      let html = res.data.content.rendered.replace('<h2>Apoio a projetos sociais</h2>', '');
      postscribe("#sobre",html.replace(/ *\[[^\]]*]/g, ''));
    });
 }

  componentDidMount(){
    this.renderNoticias();
  }

  render() {

    return (
    <Container className="painel" fluid={true}>
      <h3 className="p-3 pt-4 pl-5"><b>Sobre</b> </h3>
      <hr />
      <div id="sobre" className="p-5">
      {this.state.content}
      </div>
    </Container>
    );
  }
}

export default Painel;
