import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fab from '@material-ui/core/Fab';
import HistoryIcon from '@material-ui/icons/History';
import axios from 'axios';
import Swal from 'sweetalert2';
import './info.css';

class Info extends Component {

  constructor(props) {
    super(props);
    this.state = {html:"",last_data:{n:0,s:0,ne:0,'se-co':0},last_mouth:"jan",pe_last_mouth:"jan"};
  }

  componentDidMount(){
    axios.get(process.env.REACT_APP_API_URL + 'pld')
    .then(res => {
      this.montaTabela(res.data.data);
    });
  }

  setPLDHtml  = (html) => {
    this.setState({html})
  }

  setLasdata = (last_data) => {
    this.setState({last_data});
  }

  setLastMouth = (last_mouth) => {
    this.setState({last_mouth});
  }

  setPeLastMouth = (pe_last_mouth) => {
    this.setState({pe_last_mouth});
  }

  reordenaMeses = (pre_order) => {
    let order = {1:{'jan':null},
                 2:{'fev':null},
                 3:{'mar':null},
                 4:{'abr':null},
                 5:{'mai':null},
                 6:{'jun':null},
                 7:{'jul':null},
                 8:{'ago':null},
                 9:{'set':null},
                 10:{'out':null},
                 11:{'nov':null},
                 12:{'dez':null}
                };
    Object.keys(pre_order).forEach(function(value) {
      Object.keys(order).map(function(index){
        let order_index = Object.keys(order[index])[0];
        if(order_index === value){
          order[index][order_index] = pre_order[value];
        }
      });
    });
    return order;
  }

  reordenaTabela = (data) => {
    let tabela = {'anos':{}};
    data.forEach(function(value) {
      let key = Object.keys(value)[0];
      let split = key.split('-');
      let mes = split[0];
      let ano = split[1];
      if(tabela.anos[ano] == undefined){
        tabela.anos[ano] = {};
      }
      tabela.anos[ano][mes] = value[key];
    });
    var self = this;
    Object.keys(tabela.anos).forEach(function(value) {
      tabela.anos[value] = self.reordenaMeses(tabela.anos[value]);
    });
    return tabela;
  }

  montaTabela = (data) => {
    let html = '<table id="PLD" style="width:100%" border="0" cellspacing="0" cellpadding="0">'+
               '<tbody>'+
                 '<tr >'+
                   '<th colspan="5" >'+
                     '<p align="center">'+
                       '<b><span>Histórico PLD (R$/MWh)<u></u><u></u></span></b>'+
                     ' </p>'+
                    '</th>'+
                  '</tr>';
    html += '<tr>'+
              '<th>'+
              '<p><b><span>Mês/semana<u></u><u></u></span></b></p></th>'+
              '<th><p><b><span>N<u></u><u></u></span></b></p></th>'+
              '<th><p><b><span>NE<u></u><u></u></span></b></p></th>'+
              '<th><p><b><span>S<u></u><u></u></span></b></p></th>'+
              '<th><p><b><span>SE/CO<u></u><u></u></span></b></p></th></tr>';
    let order = this.reordenaTabela(data);
    Object.keys(order.anos).reverse().forEach(function(key) {
      Object.keys(order.anos[key]).reverse().forEach(function(data) {
        let mes_key = Object.keys(order.anos[key][data])[0];
        if(order.anos[key][data][mes_key]){
          html += '<tr>';
          html += '<td>'+mes_key +"/"+ key+'</td>';
          Object.keys(order.anos[key][data][mes_key]).sort().forEach(function(estados) {
            html += '<td>'+Number(order.anos[key][data][mes_key][estados]).toLocaleString("pt-br",{minimumFractionDigits:2 } )+'</td>';
          });
          html += '</tr>';
        }
      });
    });
    let last_year = Object.keys(order.anos).slice().pop();
    let self = this;
    Object.keys(order.anos[last_year]).map(function(value){
      let mes = Object.keys(order.anos[last_year][value]);
      if(order.anos[last_year][value][mes[0]])
      {
        self.mes = Object.keys(order.anos[last_year][value]);
        self.mes_ref = value;
        self.last_data = order.anos[last_year][value][self.mes[0]];
      }
    });
	
	console.log(last_year);
	console.log(self.mes_ref);
	console.log(Object.keys(order.anos[last_year][Number(self.mes_ref)])+"/"+last_year);
    if(self.mes_ref > 1){
		this.setPeLastMouth(Object.keys(order.anos[last_year][Number(self.mes_ref) -1])+"/"+last_year);
	}else{
		this.setPeLastMouth(Object.keys(order.anos[last_year][Number(self.mes_ref)])+"/"+last_year);
	}
    this.setLastMouth(self.mes+"/"+last_year);
    this.setLasdata(self.last_data);
    this.setPLDHtml(html);    
  }

  getInfoPLD = () =>{
    Swal.fire({
      html:this.state.html
    })
  }
  
  render() {
    return (
    <Container fluid={true} className="h-100 infogeral" >
      <Row>
        <Col className="header text-center p-3" md={12}>
          <div style={{fontSize:'18px',fontWeight:'500',paddingLeft:'15%'}}>
            PLD - última publicação
        <Fab color="primary" style={{float:'right',width:36,height:36}} onClick={()=>this.getInfoPLD()}>
          <HistoryIcon />
        </Fab>
          </div>
        </Col>
      </Row>
        <hr />
      <Row>
        <Col className="header text-md-right pl-5 text-sm-center" md={6} sm={12}>
            <p style={{fontSize:'24px',color:'#1B7B7A',margin:0}} ><b>SE/CO</b></p>
            <p style={{fontSize:'14px',color:'#1B7B7A'}} >SUDESTE</p>
        </Col>
        <Col className="header " md={6} sm={12}>
          <p className="text-md-left text-sm-center" style={{lineHeight:'3em',fontSize:'20px',color:'#202020',margin:0}} ><b>{Number(this.state.last_data['se-co']).toLocaleString('pt-br')}</b></p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="header text-md-right pl-5 text-sm-center" md={6} sm={12}>
          <p style={{fontSize:'24px',color:'#1B7B7A',margin:0}} ><b>S</b></p>
          <p style={{fontSize:'14px',color:'#1B7B7A'}} >SUL</p>
        </Col>
        <Col className="header text-center " md={6}>
          <p style={{textAlign:'left', lineHeight:'3em',fontSize:'20px',color:'#202020',margin:0}} ><b>{Number(this.state.last_data.s).toLocaleString('pt-br')}</b></p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="header text-md-right pl-5 text-sm-center" md={6}>
          <p style={{fontSize:'24px',color:'#EC7A2E',margin:0}} ><b>NE</b></p>
          <p style={{fontSize:'14px',color:'#EC7A2E'}} >NORDESTE</p>
        </Col>
        <Col className="header text-left " md={6}>
          <p style={{textAlign:'left', lineHeight:'3em',fontSize:'20px',color:'#202020',margin:0}} ><b>{Number(this.state.last_data.ne).toLocaleString("pt-br")}</b></p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="header text-md-right pl-5 text-sm-center" md={6}>
          <p style={{fontSize:'24px',color:'#EC7A2E',margin:0}} ><b>N</b></p>
          <p style={{fontSize:'14px',color:'#EC7A2E'}} >NORTE</p>
        </Col>
        <Col className="header text-left " md={6}>
          <p style={{textAlign:'left', lineHeight:'3em',fontSize:'20px',color:'#202020',margin:0}} ><b>{Number(this.state.last_data.n).toLocaleString("pt-br")}</b></p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="header text-center p-2" md={12}>
          <p style={{fontSize:'18px',color:'#272727',margin:0}} >Valores em - R$/MWh</p>
          <p style={{fontSize:'20px',color:'#288E8D'}} ><b>{this.state.last_mouth}</b></p>
        </Col>
      </Row>
    </Container>
    );
  }
}

export default Info;
