import React, { Component } from 'react';
import Loginform from '../loginform';
import ForgotPassword from '../forgotpassword';
import { Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import './content.css';

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <Loginform />
  },
  {
    path: "/forgot",
    main: () => <ForgotPassword />
  }
];

class Content extends Component {
  render() {
    return (
          <Container fluid={true} className="h-100" >
            <Row className="ImagemBackgorund" style={{'height':'100%'}}>
              <Col md={4}>
              </Col>
              <Col md={8} className="loginContainer h-100"> {routes.map((route, index) => (<Route key={index} path={route.path} exact={route.exact} component={route.main} />))} </Col>
            </Row>
          </Container>
    );
  }
}

export default Content;
