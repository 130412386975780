import React, {Component} from 'react';
import './painel.css';
import {Container, Row, Col} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import default_image from './default-avatar.jpg';
import {Button} from '@material-ui/core';
import axios from 'axios';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    AccordionActions,
    Divider,
    Checkbox,
    Tabs,
    Tab,
    AppBar,
    Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import Image from 'react-bootstrap/Image';
import Swal from 'sweetalert2';
import xlsxParser from 'xlsx-parse-json';

interface
TabPanelProps
{
    children ? : React.ReactNode;
    dir ? : string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{width: '100%'}}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

class Painel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            energy_massa: [],
            energy_pdf: '',
            tab: 0,
            pdf: '',
            email: '',
            nome: 'Nome',
            new_pass: '',
            users_checked: [],
            users: {},
            actual_index: null,
            actual_user: '',
            notifications: {},
            content: []
        };
    }

    //SETS

    setEnergyMassa = (energy_massa) => {
        this.setState({energy_massa}, function () {
            this.sendEnergyMassaRequest();
        });
    }
    setEnergyReport = (energy_pdf) => {
        this.setState({energy_pdf}, function () {
            this.sendEnergyReportRequest();
        });
    }

    setTab = (tab) => {
        this.setState({tab}, function () {
            this.setContent(this.userInfo());
        });
    }

    setPdf = (pdf) => {
        this.setState({pdf});
    }

    onInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    setEmail = (email) => {
        this.setState({email});
    }

    setNome = (nome) => {
        this.setState({nome});
    }

    setContent = (content) => {
        this.setState({content});
    }

    setIndex = (actual_index) => {
        this.setState({actual_index})
    }

    setNotifications = (notifications) => {
        this.setState({notifications});
    }

    setUsers = (users) => {
        this.setState({users});
    }

    setActualUser = (actual_user) => {
        this.setState({actual_user});
    }

    setCheckedUsers = (users_checked) => {
        this.setState({users_checked});
    }

//ENDSET

    a11yProps = (index) => {
        return {id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`};
    }

    insertFile = () => {
        let link = document.getElementById('avatar');
        link.click();
    }

    loadFile = (e) => {
        var reader = new FileReader();
        reader.onload = function () {
            var output = document.getElementById('img-edit');
            output.src = reader.result;
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    createUser = () => {
        this.setContent((
            <Row>
                <Col md={12} className={'text-center'}>
                    <h3>Cadastro de Usuários</h3>
                </Col>
                <Col md={6} className={'text-center'}>
                    <Image id={'img-edit'} src={default_image} roundedCircle onClick={this.insertFile}
                           style={{width: '50%', cursor: 'pointer'}}/>
                </Col>
                <Col md={6}>
                    <TextField
                        style={{width: '100%'}}
                        id="email_create"
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        name="email"
                        onChange={this.onInputChange}
                    />
                    <TextField
                        style={{width: '100%'}}
                        id="nome_create"
                        label="Nome"
                        margin="normal"
                        variant="outlined"
                        name="nome"
                        onChange={this.onInputChange}
                    />
                    <TextField
                        style={{width: '100%'}}
                        id="senha_create"
                        label="Senha"
                        margin="normal"
                        variant="outlined"
                        type="password"
                        name="new_pass"
                        onChange={this.onInputChange}
                    />
                </Col>
                <Col md={12}>
                    <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg"
                           onChange={(e) => this.loadFile(e)}/>
                </Col>
                <Col md={12} className={'text-center mt-3'}>
                    <Button variant="outlined" color="primary"
                            onClick={() => this.sendCreateRequest()}> Cadastrar </Button>
                </Col>
            </Row>));
    }

    sendEditRequest = () => {
        Swal.fire({
            title: 'Editando usuário ...',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        })
        let data = new FormData();
        let gameFile = document.getElementById("avatar").files[0];
        if (gameFile) {
            data.append('photo', gameFile, gameFile.name);
        }
        data.append('email', this.state.actual_user[0]);
        data.append('nome', this.state.nome);
        axios.post(process.env.REACT_APP_API_URL + 'user/edit', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(res => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: "Usuário editado com sucesso!",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                }).then((result) => {
                    window.location.reload();
                })
            })
            .catch(error => {
                Swal.fire({
                    title: 'Erro',
                    text: error.response.data.message,
                    type: 'error',
                    confirmButtonColor: '#3085d6',
                })
            });
    }

    sendCreateRequest = () => {
        let data = new FormData();
        let gameFile = document.getElementById("avatar").files[0];
        if (gameFile) {
            data.append('photo', gameFile, gameFile.name);
        }
        data.append('email', this.state.email);
        data.append('password', this.state.new_pass);
        data.append('name', this.state.nome);
        Swal.fire({
            title: 'Criando usuário ...',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        })
        axios.post(process.env.REACT_APP_API_URL + 'user', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(res => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: "Usuário criado com sucesso!",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                }).then((result) => {
                    this.findUsers();
                    this.setContent([]);
                })
            })
            .catch(error => {
                console.log(error.response);
                Swal.fire({
                    title: 'Erro',
                    text: error.response.data.message,
                    type: 'error',
                    confirmButtonColor: '#3085d6',
                }).then((result) => {
                    this.findUsers();
                })
            });
    }

    sendDeleteRequest = () => {
        Swal.fire({
            title: 'Você tem certeza que quer excluir este usuário ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, quero deletar!'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: 'Excluindo usuário ...',
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    }
                })
                axios.delete(process.env.REACT_APP_API_URL + 'user/' + this.state.actual_user[0], {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                    .then(res => {
                        Swal.fire({
                            title: 'Sucesso!',
                            text: "Usuário excluído com sucesso!",
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            this.findUsers();
                            this.setContent([]);
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        })
    }

    findUsers = () => {
        axios.get(process.env.REACT_APP_API_URL + 'users')
            .then(res => {
                this.setUsers(res.data)
            });
    }

    findUserById = (index, id) => {
        this.setIndex(index);
        this.setActualUser(id);
        this.setNome(this.state.users[index][id].Nome);
        axios.get(process.env.REACT_APP_API_URL + 'notificacoes/' + id)
            .then(res => {
                this.setNotifications(res.data);
                this.setContent(this.userInfo());
            });
    }

    handleToggle = (key, e) => {
        let users = this.state.users_checked;
        if (e.target.checked) {
            users.push(key);
        } else {
            users = users.filter(function (value, index, arr) {
                return value !== key;
            });
        }
        this.setCheckedUsers(users);
    };

    Users = (props) => {
        const {index, style} = props;
        let key = Object.keys(this.state.users[index]);

        return (
            <ListItem button style={style} key={index} onClick={() => this.findUserById(index, key)}>
                <Checkbox edge="start"
                          tabIndex={-1}
                          disableRipple
                          onClick={(e) => this.handleToggle(key, e)}
                />
                <ListItemAvatar>
                    <Avatar
                        alt={`Avatar n°`}
                        src={process.env.REACT_APP_API_URL + "public/" + this.state.users[index][key].Imagem}
                    />
                </ListItemAvatar>
                <ListItemText primary={this.state.users[index][key].Nome}/>
            </ListItem>
        );
    }

    newUserNotitifaction = () => {
        Swal.fire({
            title: 'Nova Notificação',
            html:
                '<h5>Título</h5><input id="swal-input1" class="swal2-input">' +
                '<h5>Texto </h5><textarea type="textarea" rows="5" id="swal-input2" class="swal2-input" >',
            preConfirm: (values) => {
                let titulo = document.getElementById('swal-input1').value;
                let texto = document.getElementById('swal-input2').value;
                let email = this.state.actual_user[0];
                axios.post(process.env.REACT_APP_API_URL + 'notificacoes', {
                    email: email,
                    titulo: titulo,
                    texto: texto
                }, {headers: {'Content-Type': 'aplicattion/json', 'X-Requested-With': 'XMLHttpRequest'}})
                    .then(res => {
                        let new_notifications = this.state.notifications;
                        new_notifications[Object.keys(res.data)] = res.data[Object.keys(res.data)];
                        this.setNotifications(new_notifications);
                        Swal.fire({
                            title: 'Sucesso!',
                            text: "Notificação registrada com sucesso!",
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            this.setContent(this.userInfo());
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    editUserNotitifaction = (id) => {
        Swal.fire({
            title: 'Editar Notificação',
            html:
                '<h5>Título</h5><input value="' + this.state.notifications[id].Titulo + '"id="swal-input1" class="swal2-input">' +
                '<h5>Texto </h5><textarea type="textarea" rows="5" id="swal-input2" class="swal2-input" >' + this.state.notifications[id].Texto + '</textarea>',
            focusConfirm: false,
            preConfirm: (values) => {
                let titulo = document.getElementById('swal-input1').value;
                let texto = document.getElementById('swal-input2').value;
                let email = this.state.actual_user[0];
                axios.put(process.env.REACT_APP_API_URL + 'notificacoes', {
                    notificacao: id,
                    email: email,
                    titulo: titulo,
                    texto: texto
                }, {headers: {'Content-Type': 'aplicattion/json', 'X-Requested-With': 'XMLHttpRequest'}})
                    .then(res => {
                        let new_notifications = this.state.notifications;
                        new_notifications[id] = res.data;
                        this.setNotifications(new_notifications);
                        Swal.fire({
                            title: 'Sucesso!',
                            text: "Notificação registrada com sucesso!",
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            this.setContent(this.userInfo());
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        });
    }

    tabChange = (newValue) => {
        this.setTab(newValue);
    };

    clickReport = () => {
        let link = document.getElementById('energy');
        link.click();
    }

    loadEnergyReport = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.setEnergyReport(files[0]);
    }

    sendEnergyReportRequest = () => {
        let data = new FormData();
        data.append('pdf', this.state.energy_pdf);
        data.append('nome', this.state.nome);
        data.append('email', this.state.actual_user[0]);
        return axios.post(process.env.REACT_APP_API_URL + 'energyedit', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: "O Energy Report foi atualizado!",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                })
            }).catch(err => {
                console.log(err)
            });
    }

    sendEnergyMassaRequest = () => {
        let data = new FormData();
        for (var i = 0; i < this.state.energy_massa.length; i++) {
            let file = this.state.energy_massa[i];
            data.append('files[' + i + ']', file);
        }
        axios.post(process.env.REACT_APP_API_URL + 'energymass', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: "Foram atualizados todos os Energy's Reports!",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                })
            }).catch(err => {

        });
    }

    userInfo = () => {
        if (this.state.actual_index !== null) {
            let html = [];
            let value = this.state.tab;
            let notifications = this.state.notifications;
            let build = this;
            Object.keys(notifications).reverse().forEach(function (notification) {
                html.push(
                    <Accordion
                        key={notification}
                        style={{margin: '3%', border: '2px', borderColor: '#D5D5D5', backgroundColor: '#FBFBFB'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{
                            backgroundColor: '#288E8D',
                            borderRadius: '50px',
                            color: '#fff',
                            fontSize: '35px'
                        }}/>}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header">
                            <Typography><b>{notifications[notification].Titulo + " (" + notifications[notification].Data + ")"}</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{notifications[notification].Texto}</Typography>
                        </AccordionDetails>
                        <Divider/>
                        <AccordionActions>
                            <Button color="primary" size="small"
                                    onClick={() => build.editUserNotitifaction(notification)}>Editar</Button>
                            <Button color="secondary" size="small"
                                    onClick={() => build.deleteNotification(notification)}>Excluir</Button>
                        </AccordionActions>
                    </Accordion>
                );
            });
            let email = this.state.actual_user[0];
            return (
                <Row>
                    <Col md={12}>
                        <h3>Dados do Usuário
                            <Button variant="outlined" color="secondary" style={{float: 'right'}}
                                    onClick={() => this.sendDeleteRequest()}>
                                Excluir
                            </Button>
                            <Button variant="outlined" color="primary" style={{float: 'right'}} className={"mr-2"}
                                    onClick={() => this.sendEditRequest()}>
                                Salvar
                            </Button>
                        </h3>
                    </Col>
                    <Col md={6} className={'text-center'}>
                        <Image id={'img-edit'}
                               src={"http://api.appsmartenergia.com.br/public/" + this.state.users[this.state.actual_index][this.state.actual_user].Imagem}
                               roundedCircle onClick={this.insertFile} style={{width: '50%', cursor: 'pointer'}}/>
                    </Col>
                    <Col md={6}>
                        <TextField
                            style={{width: '100%'}}
                            key={"email-edit"}
                            label="Email"
                            margin="normal"
                            value={email}
                            variant="outlined"
                            disabled
                            name="email-edit"
                        />
                        <TextField
                            style={{width: '100%'}}
                            key={"nome-edit"}
                            label="Nome"
                            margin="normal"
                            placeholder={this.state.nome}
                            variant="outlined"
                            name="nome"
                            onChange={this.onInputChange}
                        />
                    </Col>
                    <Col md={12}>
                        <input type="file"
                               id="avatar" name="avatar"
                               accept="image/png, image/jpeg" onChange={(e) => this.loadFile(e)}/>
                    </Col>
                    <Col md={12} style={{borderTop: 'solid #ff8c00 2px'}} className={'text-center mt-5'}>
                        <AppBar position="static" color="default">
                            <Tabs value={value} onChange={(event, newValue) => this.tabChange(newValue)}
                                  aria-label="full width tabs example" indicatorColor="primary" textColor="primary"
                                  variant="fullWidth">
                                <Tab label="Notificações" {...() => this.a11yProps(0)} />
                                <Tab label="Energy Report" {...() => this.a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                    </Col>
                    <TabPanel value={this.state.tab} index={0}>
                        <Col id={'notification'} md={12} className={'text-center '}
                             style={{height: '300px', overflow: 'auto'}}>
                            <h3 className={"mt-2"}>Notificações</h3>
                            {html}
                        </Col>
                        <Col md={12} className={'text-center mt-1 mb-2'}>
                            <Button variant="outlined" color="primary" onClick={() => this.newUserNotitifaction()}>
                                Nova Notificação
                            </Button>
                        </Col>
                    </TabPanel>
                    <TabPanel value={this.state.tab} index={1}>
                        <Col id={'energy_report'} md={12} className={'text-center'}>
                            <h3 className={"mt-2"}>Energy Report</h3>
                            {this.state.users[this.state.actual_index][this.state.actual_user].Energy_Report ?
                                <h5 className={"mt-2"}>ref
                                    : {this.state.users[this.state.actual_index][this.state.actual_user].Energy_Report}</h5>
                                :
                                false
                            }
                            <Button variant="outlined" color="primary" className={"mr-2"}
                                    onClick={() => this.clickReport()}>Atualizar Energy Report</Button>
                            <input id={'energy'} className={'hidden'} style={{display: 'none'}} type="file"
                                   onChange={(e) => this.loadEnergyReport(e)}/>
                        </Col>
                    </TabPanel>
                </Row>
            );
        } else {
            return false;
        }
    }

    deleteNotification = (id) => {
        axios.delete(process.env.REACT_APP_API_URL + 'notificacoes/' + this.state.actual_user[0] + '/' + id, {}, {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(res => {
                let new_notifications = this.state.notifications;
                delete new_notifications[id];
                this.setNotifications(new_notifications);
                Swal.fire({
                    title: 'Sucesso!',
                    text: "Notificação removida com sucesso!",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                }).then((result) => {
                    this.setContent(this.userInfo());
                })
            })
            .catch(error => {
            });
    }

    massNotification = () => {
        if (this.state.users_checked.length) {
            let success = false;
            Swal.fire({
                title: 'Nova Notificação em Massa',
                html:
                    '<h5>Título</h5><input id="swal-input1" class="swal2-input">' +
                    '<h5>Texto </h5><textarea type="textarea" rows="5" id="swal-input2" class="swal2-input" >',
                preConfirm: () => {
                    let titulo = document.getElementById('swal-input1').value;
                    let texto = document.getElementById('swal-input2').value;
                    let i = 0;
                    for (let userObject of this.state.users_checked) {
                        success = true;
                        axios.post(process.env.REACT_APP_API_URL + 'notificacoes/mass', {
                            email: userObject[0],
                            titulo: titulo,
                            texto: texto
                        }, {headers: {'Content-Type': 'aplicattion/json', 'X-Requested-With': 'XMLHttpRequest'}})
                            .then(res => {
                                if (i == this.state.users_checked.length - 1) {
                                    Swal.fire({
                                        title: 'Sucesso!',
                                        text: "Notificação registrada com sucesso!",
                                        type: 'success',
                                        confirmButtonColor: '#3085d6',
                                    }).then((result) => {
                                        window.location.reload();
                                    });
                                }
                                i = i + 1;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            });
        } else {
            Swal.fire({
                title: 'Erro',
                text: 'Você deve selecionar pelo menos 1 usuário para fazer esta ação !',
                type: 'error',
                confirmButtonColor: '#3085d6',
            });
        }
    }

    handleToggleAll = (event) => {
        let users = [];
        let all_users = this.state.users.map(function (data) {
            return Object.keys(data)
        });
        if (event.target.checked) {
            users = all_users;
            document.querySelectorAll('#lista_users span.MuiButtonBase-root').forEach(function (data) {
                data.classList.add('Mui-checked');
                let svg = data.querySelector('svg');
                svg.innerHTML = '<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>';
            });
        } else {
            users = [];
            document.querySelectorAll('#lista_users span.MuiButtonBase-root').forEach(function (data) {
                data.classList.remove('Mui-checked');
                let svg = data.querySelector('svg');
                svg.innerHTML = '<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>';
            });
        }
        this.setCheckedUsers(users);
    }

    componentDidMount() {
        this.findUsers();
    }

    importExcel = () => {
        let xlsx = document.getElementById('xlsx');
        xlsx.click();
    }

    importInfoSetorial = () => {
        let pdf = document.getElementById('pdf');
        pdf.click();
    }

    importEnergyMassa = () => {
        let energy_massa = document.getElementById('energy_massa');
        energy_massa.click();
    }

    loadEnergyMassa = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.setEnergyMassa(files)
    }

    loadPdf = (e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage(files[0]);
        this.setPdf(files[0]);
    }

    createImage = (file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.fileUpload();
        };
        let teste = reader.readAsDataURL(file);
    }

    fileUpload() {
        let data = new FormData();
        data.append('pdf', this.state.pdf)
        return axios.post(process.env.REACT_APP_API_URL + 'pdf', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(res => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: "Pdf de Info. Setorial atualizado!",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                })
            })
            .catch(error => {
            });
    }

    loadExcel = () => {
        var reader = new FileReader();
        var output = document.getElementById('xlsx');

        Swal.fire({
            title: 'Processando documento ...',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });
        xlsxParser
            .onFileSelection(output.files[0])
            .then(data => {
                var parsedData = data;
                axios.post(process.env.REACT_APP_API_URL + 'pld', {PLD: parsedData['PLD']}, {
                    headers: {
                        'Content-Type': 'aplicattion/json',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                    .then(res => {
                        Swal.fire({
                            title: 'Sucesso!',
                            text: "Tabela PLD registrada com sucesso!",
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                        }).then((result) => {
                            window.location.reload();
                        });
                    })
                    .catch(error => {
                    });
            });
    }

    render() {
        return (
            <Container className="painel h-100" fluid={true}>
                <h3 className="p-3 pt-4 pl-5"><b>Painel Administrativo</b>
                    <Button variant="outlined" color="primary" style={{float: 'right'}}
                            onClick={() => this.importExcel()}>
                        Importar PLD
                    </Button>
                    <Button variant="outlined" color="primary" style={{float: 'right'}}
                            onClick={() => this.importInfoSetorial()}>
                        PDF Info. Setorial
                    </Button>
                    <Button variant="outlined" color="primary" style={{float: 'right'}}
                            onClick={() => this.importEnergyMassa()}>
                        Energy Report em Massa
                    </Button>
                    <input id={'xlsx'} style={{display: 'none'}} type="file" onChange={(e) => this.loadExcel(e)}/>
                    <input id={'pdf'} style={{display: 'none'}} type="file" onChange={(e) => this.loadPdf(e)}/>
                    <input id={'energy_massa'} style={{display: 'none'}} type="file"
                           onChange={(e) => this.loadEnergyMassa(e)} multiple/>
                </h3>
                <hr/>
                <Row className="h-100">
                    <Col md={6} style={{borderRight: 'solid #ff8c00 2px'}}>
                        <h3 className="p-3 pt-2 pl-1"><b className={'md-m-5'}>Usuários</b>
                            <Button variant="outlined" color="primary" style={{float: 'right'}}
                                    onClick={this.createUser}>
                                Cadastrar Usuário
                            </Button>
                            <Button variant="outlined" color="primary" style={{float: 'right', marginRight: '5px'}}
                                    onClick={() => this.massNotification()}>
                                Notificação em Massa
                            </Button>
                        </h3>
                        <hr/>
                        <Checkbox
                            tabIndex={-1}
                            disableRipple
                            onClick={(e) => this.handleToggleAll(e)}
                        /> Selecionar Todos
                        <div id='lista_users'>
                            <FixedSizeList height={500} width={'100%'} itemSize={46}
                                           itemCount={this.state.users.length}>
                                {this.Users}
                            </FixedSizeList>
                        </div>
                    </Col>
                    <Col md={6}>
                        {this.state.content}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Painel;
