import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './navbuttons.css';
import SVG from 'react-inlinesvg';
import Button from '@material-ui/core/Button';
import SvgIcons from "../../graficos/graficosicons/icons/allIcons";


const buttons = {0:{link:'/dashboard/sobre',label:'Sobre',icon:'ic_dash_infosetorial' },
                 1:{link:'/dashboard/info-setorial',label:'Info Setorial',icon:'ic_dash_energyreport'},
                 2:{link:'/dashboard/noticias',label:'Noticias',icon:'ic_dash_noticias'},
                 3:{link:'/dashboard/graficos/economia',label:'Economia',icon:'ic_dash_economia'},
                 4:{link:'/dashboard/graficos/consumo',label:'Consumo',icon:'ic_dash_consumo'},
                 5:{link:'/dashboard/graficos/demanda',label:'Demanda',icon:'ic_dash_demanda'},
                 6:{link:'/dashboard/graficos/calendario',label:'Obrigações CCEE',icon:'ic_dash_calendario'},
                 7:{link:'/dashboard/graficos/indicadores',label:'Indicadores',icon:'ic_dash_indicadores'},
                 8:{link:'/dashboard/graficos/auditoria',label:'Auditoria e outros Custos',icon:'ic_dash_auditoria'}};

class Navbuttons extends Component {
  render() {
    let html = []
    for(let key in buttons){
      let MyIcon = SvgIcons[buttons[key].icon];
      let CollisionLink = React.forwardRef((props, ref) => (
        <Link innerRef={ref} to={buttons[key].link} {...props} />
      ));
      
      html.push(
              <Button component={CollisionLink}>
                <li className="w-100">
                  <MyIcon />
                  <hr className="w-100"/>
                  <h4>{buttons[key].label}</h4>
                </li>
              </Button>);
    }

    return (
      <Container fluid={true} className="h-100 navbuttons">
      <Row className="h-100">
        <ul className="w-100 dashboard">
          {html}
        </ul>
      </Row>
    </Container>
    );
  }
}

export default Navbuttons;
