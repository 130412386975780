import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Grafico from '../grafico/grafico';
import Graficosicons from '../graficosicons/graficosicons';

import {connect} from 'react-redux';
import {trocaSidebar} from '../../../../actions/actionTrocaSidebar';
import {bindActionCreators} from 'redux';

const graficos = {
  1:{id:1,nome:'Economia',alt:'Economia Mensal',imagem:'ic_economia',sub_graficos:{1:'Economia Mensal',
                                                                                   2:'Economia Acumulada'}},
  3:{id:3,nome:'Consumo', alt:'Consumo Mensal',imagem:'ic_consumo',sub_graficos:{3 : 'Consumo Mensal',
                                                                                 4 : 'Ponta x Fora de Ponta',
                                                                                 5 : 'Consumo Diário (MWh)',
                                                                                 6 : 'Média de Consumo Horário(kWh)',
                                                                                 12: 'Fator de Carga',
                                                                                 16: '% Contratado empresa'}},
  7:{id:7,nome:'Demanda',alt:'Demanda Ponta (kW)',imagem:'ic_demanda',sub_graficos:{7 :'Demanda Ponta (kW)',
                                                                                    8 :'Demanda Fora Ponta (kW)'}},
  9:{id:9,nome:'Obrigações CCEE',alt:'Obrigações CCEE',imagem:'consumo',sub_graficos:0},
  10:{id:10,nome:'Auditoria e Redução de Custos',alt:'Auditoria e Redução de Custos (R$)',imagem:'ic_auditoria',sub_graficos:0},
  11:{id:11,nome:'Indicadores',alt:'Indicador R$/MWh',imagem:'ic_indicadores',sub_graficos:{11: 'Indicador R$/MWh',
                                                                                       14:'Indicador Personalizado 1',
                                                                                       15:'Indicador Personalizado 2'}},
};

class Content extends Component {

  constructor(props){
    super(props)
    this.state = {nome_grafico:graficos[this.props.grafico_ativo].alt, grafico_atual : this.props.grafico_ativo, sub_grafico_atual:0}
  }

  setNomeGrafico = (id,id_ant) => {
    let nome = (id_ant)? graficos[this.state.grafico_atual].sub_graficos[id]: graficos[id].alt;
    this.setState({nome_grafico:nome})
  }

  setGraficoatual = (grafico_atual) => {
    this.setState({grafico_atual})
  }

  setSubGraficoatual = (sub_grafico_atual) => {
    this.setState({sub_grafico_atual})
  }

  render() {
    this.props.trocaSidebar("Grafico");

    return (
    <Container fluid={true} className="h-100">
      <Row className="h-100" >
        <Col md={10}  className="h-100 p-1" >
          <Grafico nome_grafico={this.state.nome_grafico} 
                   grafico_atual={graficos[this.state.grafico_atual]}
                   sub_grafico_atual={this.state.sub_grafico_atual}
                   alteraNomeGrafico={this.setNomeGrafico}
                   alteraGraficoatual={this.setGraficoatual} 
                   alteraSubgraficoatual={this.setSubGraficoatual}/> 
        </Col>
        <Col md={2}  className="h-100 p-1"> 
          <Graficosicons graficos={graficos}
                         alteraGraficoatual={this.setGraficoatual} 
                         alteraNomeGrafico={this.setNomeGrafico}
                         alteraSubgraficoatual={this.setSubGraficoatual} /> 
        </Col>
      </Row>
    </Container>
    );
  }
}

function mapStateToProps(state){
  return {
    grafico_ativo:state.grafico_ativo,
    sidebar_ativa:state.sidebar_ativa
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({trocaSidebar:trocaSidebar}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Content);
