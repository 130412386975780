import React , {Component} from 'react';

class Notfound extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <h3>Not founde</h3>
        );
    }
}
export default Notfound
