import { combineReducers } from 'redux';

import unidadeAtiva from './unidadeAtiva';
import graficoAtivo from './graficoAtivo';
import sidebarAtiva from './sidebarAtiva';

const allReducers = combineReducers({
  unidade_ativa:unidadeAtiva,
  grafico_ativo:graficoAtivo,
  sidebar_ativa:sidebarAtiva
});

export default allReducers;