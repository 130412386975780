import React, { Component } from 'react';
import { Form, Button, Media} from 'react-bootstrap';
import smartlogo from './logo_completa.png';
import { Link } from "react-router-dom";
import axios from 'axios';
import { history } from "../../routes/history";
import {STORAGE_Email,STORAGE_Units,STORAGE_Nome,STORAGE_Imagem} from '../../routes/auth';
import {TextField,CircularProgress} from '@material-ui/core';
import './loginform.css';

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  error: null
};

class Loginform extends Component {
  constructor(props){
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  setError = (error) => {
    this.setState({error});
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.setError(null);
  };
  showError = () => {
    if(this.state.error != null){
      return false
    }else {
      return false;
    }
  }
  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.fazerAutentificacao();
    }
  }

  render() {
    const {
      email,
      passwordOne,
      error
    } = this.state;

    const isInvalid =
    passwordOne === '' ||
    email === '' ;

    return (
      <Form className="h-100">
        <Media className="justify-content-center mb-5">
        <img
          className="mr-3"
          src={smartlogo}
          alt="Smart Energia logo"
        />
        </Media>
        <Form.Group controlId="formBasicEmail" className="loginInput d-flex justify-content-center " >
          <TextField  style={{width:'35%'}} name="email" value={email} onChange={this.onChange} type="email" label="Usuário"   variant="outlined"  margin="normal" autoComplete="email" />
        </Form.Group>
        <Form.Group controlId="formBasicPassword" className="loginInput d-flex justify-content-center mb-0">
          <TextField  style={{width:'35%'}} onKeyPress={this.handleKeyPress} size="lg" name="passwordOne" value={passwordOne} variant="outlined" margin="normal" onChange={this.onChange} type="password" label="Senha" />
        </Form.Group>
        <Form.Group controlId="formForgotPass" className="d-flex justify-content-center loginForgot" style={{paddingLeft:'11rem'}}>
        <Link to="/forgot" style={{color:'#385682'}}>
          Esqueceu a senha / Alterar senha?
        </Link>
        </Form.Group>
        <Form.Group className="d-flex justify-content-center">
          <Button id={"formsubmit"} disabled={isInvalid} onClick={this.fazerAutentificacao} className="loginSubmit" >
          Entrar
          </Button>
        </Form.Group>
        <Form.Group className="d-flex justify-content-center error" style={{color:'red'}}>
        {error && <p>{error}</p>}
        </Form.Group>
        <Form.Group className="d-flex justify-content-center text-center">
          <p style={{fontFamily:'Roboto',fontSize:'16px',marginTop:'1rem'}}>Não é cliente? Envie e-mail para:<br/><a>contato@energiasmart.com.br</a></p>
        </Form.Group>
        <CircularProgress style={{display:'none'}}/>
    </Form>
    );
  }

  // rota = 'https://api.appsmartenergia.com.br';
  rota = process.env.REACT_APP_API_URL;


  fazerAutentificacao = (e) =>{
    let button = document.getElementById('formsubmit');
    button.innerHTML = '<div class="text-center MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style="color:#EC7A2E;width: 40px; height: 40px;"><svg class="MuiCircularProgress-svg" viewBox="22 22 44 44"><circle class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate" cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"></circle></svg></div>';
    axios.post(this.rota + '/auth',{
      email: this.state.email,
      senha: this.state.passwordOne
    },{headers:{'Content-Type': 'application/json'}})
    .then(res => {
      button.innerHTML = "Entrar";
      localStorage.setItem(STORAGE_Email,res.data.user);
      localStorage.setItem(STORAGE_Nome,res.data.data.original.Nome);
      localStorage.setItem(STORAGE_Imagem,res.data.data.original.Imagem);
      if(res.data.units === null){
        if(res.data.user === "fernando__BANKenergiasmart_com_br" || res.data.user === "back1__BANKenergiasmart_com_br") {
          localStorage.setItem(STORAGE_Units,["ADMINISTRADOR"]);
        } else {
          this.setError("Houve um erro ao carregar os dados de sua unidade, favor entrar em contato");
          return false;
        }
      } else {
        localStorage.setItem(STORAGE_Units,Object.keys(res.data.units));
      }
      history.push('/dashboard');
    })
    .catch(error =>{
      button.innerHTML = "Entrar";
      this.setError("Login ou senha estão errados");
    });
  }
}

export default Loginform;
