import React, { Component } from 'react';
import './painel.css';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { MobilePDFReader} from 'react-read-pdf';
import {CircularProgress} from '@material-ui/core';
import {STORAGE_Email}  from '../../../../routes/auth';

class Painel extends Component {
  constructor(props){
    super(props)
    this.state = { numPages: null,pageNumber: 1,pdfUrl:0}
  }

  componentWillMount() {
    this.getPdfUrl();
  }

  setPdfurl = (pdfUrl) => {
    this.setState({pdfUrl});
  }

  setPageNumber = (pageNumber) => {
    document.getElementById('pdfview').scrollTop = 0;
    this.setState({pageNumber});
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  getPdfUrl = () => {
    let email = localStorage.getItem(STORAGE_Email);
    axios.get(process.env.REACT_APP_API_URL + 'energy/'+email)
    .then(res =>{
      if(res.data.data.Energy_Report){
        this.setPdfurl(process.env.REACT_APP_API_URL + 'getpdf/energy_report/'+res.data.data.Energy_Report);
      }else {
        this.setPdfurl(null);
      }
    });
  }

  render() {

    return (
    <Container className="painel" fluid={true}>
      <div id={"pdfview"}style={{height:'100%',overflowX:'unset'}}>
        {this.state.pdfUrl?

          <MobilePDFReader scale={1} isShowFooter={false} isShowHeader={false} onDocumentComplete={this.onDocumentLoadSuccess} url={this.state.pdfUrl} />  
        :
        (this.state.pdfUrl === null)?
          <div className={"noenergy"}>
          <h3> Energy Report ainda não publicado</h3>
          </div>
        :
          <div className={"loaderenergy"}>
          <CircularProgress style={{height:80,width:80}}/>
          </div>
        }

      </div>
    </Container>
    );
  }
}

export default Painel;
