import React, { Component } from 'react';
import { Navbar, Nav ,Form,Popover,OverlayTrigger} from 'react-bootstrap';
import { Link } from "react-router-dom";
import logosmart from '../../../assets/logo_app.png'; 
import './navbardash.css';
import SVG from 'react-inlinesvg';
import Logout from '../logout';
import {STORAGE_Email,STORAGE_Units,STORAGE_Nome,STORAGE_Imagem}  from '../../../routes/auth';
import {connect} from 'react-redux';
import {switchUnidade} from '../../../actions';
import {bindActionCreators} from 'redux';
import {Select,MenuItem,OutlinedInput,IconButton,List,ListItem,Badge,Button,Avatar} from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import axios from 'axios';
import {ReactComponent as Icenergyreport} from '../../../assets/ic_energyreport.svg';
import {ReactComponent as Icnotification} from '../../../assets/ic_notificaoes.svg';

class Navbardash extends Component {
  constructor(props){
    super(props);
    this.state = { notificacoes:{}}
  }
  _isMounted = false;

  setNotificacoes = (notificacoes) =>{
    this.setState({notificacoes})
  }

  componentDidMount() {
    this._isMounted = true;
    this.renderNoticias();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderNoticias = () =>{
    let id = localStorage.getItem(STORAGE_Email)
    axios.get(process.env.REACT_APP_API_URL + 'notificacoes/naolidas/'+id)
    .then(res => {
      if (this._isMounted) {
      this.setNotificacoes(res.data)
      }
  });
  }

  trocaUnidade = (event) => {
    let selecao = event.target.value;
    this.props.switchUnidade({id:selecao,nome:selecao});
  }

  render() {
    let notificacoes = this.state.notificacoes;
    let noti_html = [];
    let qtd = 0;
    for(let key in notificacoes){
      qtd++;
      noti_html.push(
        <ListItem alignItems="flex-start">
          <Button href="https://appsmartenergia.com.br/dashboard/notificacoes" color="primary" variant="contained" style={{justifyContent:"left",textAlign:"left",width:"100%",backgroundColor:"#FF8C00",color:"#fff",}} >
          {notificacoes[key].Titulo}
        </Button>
          
        </ListItem>
      );
      noti_html.push(<hr style={{ margin:'0',backgroundColor:'#fff'}}/>);
    }
    const popover = (
      <Popover id="popover-basic">
        <List>
        {noti_html}
        </List>
          <a href="https://appsmartenergia.com.br/dashboard/notificacoes">
            <Popover.Title style={{backgroundColor:'#288d8c',color:'#fff'}} className="text-center" as="h3">Ver todas</Popover.Title>
          </a>
      </Popover>
    );

    let unidades = localStorage.getItem(STORAGE_Units).split(',');
    let options = [];
    let nome_cliente = localStorage.getItem(STORAGE_Nome);
    let imagem_cliente =  localStorage.getItem(STORAGE_Imagem);
    let select = [];
    if(unidades[0] != "ADMINISTRADOR"){
    for(let key in unidades){
      let item = (key==='TOTAL')? <em> <MenuItem value={unidades[key]} >{unidades[key]}</MenuItem></em>:<MenuItem value={unidades[key]} >{unidades[key]}</MenuItem>;
      options.push(item);
    }
    select.push((      <Select
      value={this.props.unidade.nome}
      displayEmpty={true}
      className="mr-sm-2 w-100 pesquisa" 
      placeholder="Nome da empresa"
      onChange={this.trocaUnidade}
      style={{"backgroundColor":"#fff","color":"#385682","border-radius": "5px"}}
      input={<OutlinedInput>   </OutlinedInput>}
      IconComponent={() => (
        <SwapHorizIcon />
      )}>
    >{options}  </Select>));
    }
    return (
    <Navbar bg="lg" expand="lg" className="h-100 w-100 logo-container">
      <Navbar.Brand style={{width:'15%'}} href={logosmart} className="h-100 logo">
        <img
          src={logosmart}
          height="100%"
          className="d-inline-block align-top"
          alt="Smart"
        />
      </Navbar.Brand>
      <Form style={{width:'50%'}} inline className="pesquisa-container">
            {select}
        
      </Form>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" style={{color:'#fff',zIndex:10, backgroundColor:'#385682',"width":'100%',"text-align":"center"}}>
        <Nav className="mr-auto">
        <Nav.Link><Link to="/dashboard">Inicio</Link></Nav.Link>
        <Nav.Link><Link to="/dashboard/relatorios">Energy Report</Link></Nav.Link>
        <Nav.Link><Link to="/dashboard/graficos">Graficos</Link></Nav.Link>
        <Nav.Link><Link to="/dashboard/info-setorial">Info. Setorial</Link></Nav.Link>
        <Nav.Link><Link to="/dashboard">Sair</Link></Nav.Link>
      </Nav>
      </Navbar.Collapse>
      <div className="user-info">
      <h4 style={{display: '-webkit-box'}} className="m-0"><p style={{marginTop:'8%'}}>{nome_cliente}</p>
      <Avatar className="avatar"
                  alt={`Avatar n°`}
                  src={process.env.REACT_APP_API_URL + "public/"+imagem_cliente}
          />
        </h4>
      </div>
        <Nav className="ml-auto nav-icons" >
          <IconButton ><Link to="/dashboard/info-setorial"><Icenergyreport /></Link></IconButton>
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <Badge badgeContent={qtd} color="secondary">
            <IconButton><Icnotification /></IconButton>
            </Badge>
          </OverlayTrigger>
          <IconButton><Logout  /></IconButton>
        </Nav>
    </Navbar>  
    );
  }
}

function mapStateToProps(state){
  return {
    unidade:state.unidade_ativa
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({switchUnidade:switchUnidade}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Navbardash);
