import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import Logout from '../logout';
import './sidebar.css';
import SVG from 'react-inlinesvg';
import {STORAGE_Email}  from '../../../routes/auth';

import {connect} from 'react-redux';
import {trocaSidebar} from '../../../actions/actionTrocaSidebar';
import {bindActionCreators} from 'redux';

import {ReactComponent as Ichome} from '../../../assets/ic_home.svg';
import {ReactComponent as Icenergyreport} from '../../../assets/ic_energyreport.svg';
import {ReactComponent as Icnotification} from '../../../assets/ic_notificaoes.svg';
import {ReactComponent as Icgraficos} from '../../../assets/ic_graficos.svg';
import {ReactComponent as Icinfosetorial} from '../../../assets/ic_infosetorial.svg';
import {ReactComponent as Icnoticias} from '../../../assets/ic_noticias.svg';
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {active : this.props.sidebar_ativa}
  }

  _handleClick(menuItem) { 
    this.setState({ active: menuItem });
  }

  componentDidUpdate() {
    this._handleClick.bind(this, this.props.sidebar_ativa)
  }

  render() {
    let teste= [];
    let id = localStorage.getItem(STORAGE_Email)
    if(id === "fernando__BANKenergiasmart_com_br" || id  === "back1__BANKenergiasmart_com") {
      teste = (      
      <Link to="/dashboard/admin"  
        style={{height:"100px",display:'flex'}} 
        onClick={this._handleClick.bind(this, "Admin")}>
      <div className="w-100"  > 
        <Nav.Link as="div"className="w-100" > 
          <Ichome />
            Admin
        </Nav.Link>
      </div> 
    <div className={this.props.sidebar_ativa === "Admin" ? 'sidebar-active' : 'sidebar-off'}></div>
    </Link>
    );
    }
    return (
    <Nav defaultActiveKey="/dashboard" className="flex-column sidebar h-100">
      {teste}
      <Link to="/dashboard"  
            style={{height:"100px",display:'flex'}} 
            onClick={this._handleClick.bind(this, "Inicio")} >
        <div className="w-100"  > 
          <Nav.Link as="div"className="w-100" > 
            <Ichome />
              Inicio
          </Nav.Link>
        </div> 
          <div className={this.props.sidebar_ativa === "Inicio" ? 'sidebar-active' : 'sidebar-off'}></div>
      </Link>
      <Link to="/dashboard/graficos" 
            style={{height:"100px",display:'flex'}} 
            onClick={this._handleClick.bind(this, "Grafico")}>
        <div className="w-100"  > 
          <Nav.Link as="div"> 
            <Icgraficos />
              Gráficos
            </Nav.Link>
        </div>
        <div className={this.props.sidebar_ativa === "Grafico" ? 'sidebar-active' : 'sidebar-off'}></div>
      </Link>      
      <Link to="/dashboard/noticias"
                  style={{height:"100px",display:'flex'}} 
                  onClick={this._handleClick.bind(this, "Noticias")}>
                    <div className="w-100"  > 
        <Nav.Link as="div">
           <Icnoticias />
           Notícias
           </Nav.Link>
           </div>
           <div className={this.props.sidebar_ativa === "Noticias" ? 'sidebar-active' : 'sidebar-off'}></div>
      </Link>
      <Link to="/dashboard/notificacoes"
            style={{height:"100px",display:'flex'}} 
            onClick={this._handleClick.bind(this, "Notificacoes")}>
        <div className="w-100"  > 
          <Nav.Link as="div">
            <Icnotification />
            Notificações
          </Nav.Link>
        </div>
        <div className={this.props.sidebar_ativa === "Notificacoes" ? 'sidebar-active' : 'sidebar-off'}></div>
      </Link>
      <Link to="/dashboard/info-setorial"
            style={{height:"100px",display:'flex'}} 
            onClick={this._handleClick.bind(this, "Info. Setorial")}>
        <div className="w-100"  > 
          <Nav.Link as="div">
             <Icenergyreport />
             Info. Setorial
          </Nav.Link>
        </div>
        <div className={this.props.sidebar_ativa === "Info. Setorial" ? 'sidebar-active' : 'sidebar-off'}></div>
      </Link>
      <Link to="/dashboard/relatorio"
            style={{height:"100px",display:'flex'}} 
            onClick={this._handleClick.bind(this, "Relatorio")}>
        <div className="w-100"  > 
          <Nav.Link as="div">
             <Icenergyreport />
             Energy Report
          </Nav.Link>
        </div>
        <div className={this.props.sidebar_ativa === "Relatorio" ? 'sidebar-active' : 'sidebar-off'}></div>
      </Link>
      <Link to="/dashboard/sobre"
            style={{height:"100px",display:'flex'}} 
            onClick={this._handleClick.bind(this, "Sobre")}>
        <div className="w-100"  > 
          <Nav.Link as="div">
             <Icinfosetorial />
             Sobre
          </Nav.Link>
        </div>
        <div className={this.props.sidebar_ativa === "Sobre" ? 'sidebar-active' : 'sidebar-off'}></div>
      </Link>
      <Logout label="Sair"/> 
    </Nav>
    );
  }
}

function mapStateToProps(state){
  return {
    sidebar_ativa:state.sidebar_ativa
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({trocaSidebar:trocaSidebar}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Sidebar);
