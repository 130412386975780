import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom' ;
import Dashboard from '../dashboard/content';
import App from '../App';
import Notfound from './notfound';
import PrivateRoute from './privateroute'
import AdminRoute from './adminroute';

class Routes extends Component {
    render(){
        return (
    <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} component={App} />
          <Route path="/forgot" exact={true} component={App} />
          <PrivateRoute path="/dashboard" exact={true} render={()=><Dashboard/>}/>
          <AdminRoute path="/dashboard/admin" exact={true} render={()=><Dashboard/>}/>
          <PrivateRoute path="/dashboard/graficos" exact={true} render={()=><Dashboard/>}/>
          <PrivateRoute path="/dashboard/graficos/economia" exact={true} render={()=> <Dashboard grafico={1} />}/>
          <PrivateRoute path="/dashboard/graficos/consumo" exact={true} render={()=> <Dashboard grafico={3} />}/>
          <PrivateRoute path="/dashboard/graficos/demanda" exact={true} render={()=> <Dashboard grafico={7} />}/>
          <PrivateRoute path="/dashboard/graficos/calendario" exact={true} render={()=> <Dashboard grafico={9} />}/>
          <PrivateRoute path="/dashboard/graficos/indicadores" exact={true} render={()=> <Dashboard grafico={11} />}/>
          <PrivateRoute path="/dashboard/graficos/auditoria" exact={true} render={()=> <Dashboard grafico={10} />}/>
          <PrivateRoute path="/dashboard/noticias" exact={true} render={()=><Dashboard/>}/>
          <PrivateRoute path="/dashboard/notificacoes" exact={true} render={()=><Dashboard/>}/>
          <PrivateRoute path="/dashboard/relatorio" exact={true} render={()=><Dashboard/>}/>
          <PrivateRoute path="/dashboard/info-setorial" exact={true} render={()=><Dashboard/>}/>
          <PrivateRoute path="/dashboard/sobre" exact={true} render={()=><Dashboard/>}/>
          <Route component={Notfound}/>
      </Switch>
    </ BrowserRouter>
);
    }
}
export default Routes;