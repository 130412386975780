import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navbuttons from './navbuttons';
import Info from './info';

import {connect} from 'react-redux';
import {trocaSidebar} from '../../../actions/actionTrocaSidebar';
import {bindActionCreators} from 'redux';
import './infogeral.css';

class Infogeral extends Component {
  render() {
    this.props.trocaSidebar("Inicio");
    return (
    <Container fluid={true} className="h-100">
      <Row className="h-100">
        <Col md={5} className="info h-auto"><Info /></Col>
        <Col md={7} className="info h-auto" > <Navbuttons /> </Col>
      </Row>
    </Container>
    );
  }
}

function mapStateToProps(state){
  return {
    sidebar_ativa:state.sidebar_ativa
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({trocaSidebar:trocaSidebar}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Infogeral);
