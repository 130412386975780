import {ReactComponent as ICEconomia} from "../../../../../assets/ic_economia.svg";
import {ReactComponent as IcConsumo} from "../../../../../assets/ic_consumo.svg";
import {ReactComponent as IcDemanda} from "../../../../../assets/ic_demanda.svg";
import {ReactComponent as Consumo} from "../../../../../assets/consumo.svg";
import {ReactComponent as IcAuditoria} from "../../../../../assets/ic_auditoria.svg";
import {ReactComponent as IcIndicadores} from "../../../../../assets/ic_indicadores.svg";


import {ReactComponent as ICDashInfoSetorial} from "../../../../../assets/ic_dash_infosetorial.svg";
import {ReactComponent as ICDashEnergyReport} from "../../../../../assets/ic_dash_energyreport.svg";
import {ReactComponent as ICDashNoticias} from "../../../../../assets/ic_dash_noticias.svg";
import {ReactComponent as ICDashEconomia} from "../../../../../assets/ic_dash_economia.svg";
import {ReactComponent as ICDashConsumo} from "../../../../../assets/ic_dash_consumo.svg";
import {ReactComponent as ICDashDemanda} from "../../../../../assets/ic_dash_demanda.svg";
import {ReactComponent as ICDashCalendario} from "../../../../../assets/ic_dash_calendario.svg";
import {ReactComponent as ICDashIndicadores} from "../../../../../assets/ic_dash_indicadores.svg";
import {ReactComponent as ICDashAuditoria} from "../../../../../assets/ic_dash_auditoria.svg";

const SvgIcons = {
    ic_economia: ICEconomia,
    ic_consumo: IcConsumo,
    ic_demanda: IcDemanda,
    consumo: Consumo,
    ic_auditoria: IcAuditoria,
    ic_indicadores: IcIndicadores,
    ic_dash_infosetorial: ICDashInfoSetorial,
    ic_dash_energyreport: ICDashEnergyReport,
    ic_dash_noticias: ICDashNoticias,
    ic_dash_economia: ICDashEconomia,
    ic_dash_consumo: ICDashConsumo,
    ic_dash_demanda: ICDashDemanda,
    ic_dash_calendario: ICDashCalendario,
    ic_dash_indicadores: ICDashIndicadores,
    ic_dash_auditoria: ICDashAuditoria,
};

export default SvgIcons;
