import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import {Nav} from 'react-bootstrap';
import {STORAGE_Email,STORAGE_Units}  from '../../../routes/auth';
import { history } from "../../../routes/history";

import {ReactComponent as Iclogout}from  '../../../assets/logout.svg';

class Logout extends Component {
  render() {
    return (
      <a  href="#" 
          style={{display:'flex'}} 
          onClick={this.logout}>
        <div className="w-100"  > 
    <Nav.Link as="div">
      <button style={{backgroundColor:'#ffffff00',border:'none'}}><Iclogout /></button>
      {this.props.label}
    </Nav.Link>
    </div>
    <div className={this.props.sidebar_ativa === "Sair" ? 'sidebar-active' : 'sidebar-off'}></div>
    </a>

    );
  }

  logout = (e) =>{
    localStorage.removeItem(STORAGE_Email);
    localStorage.removeItem(STORAGE_Units);
    history.push('/');
  }
}

export default Logout;
